import { mapGetters } from 'vuex';

export default {
  data: function() {
    return {};
  },
  computed: {
    ...mapGetters({
      useTicketFlag: 'event/useTicketFlag',
      hasCustomersRole: 'auth/hasCustomersRole',
      hasManageCurrencyRole: 'auth/hasManageCurrencyRole',
      hasManageQrRole: 'auth/hasManageQrRole',
      eventGroups: 'event/eventGroups',
    }),
    navs() {
      return [
        {
          id: '01',
          name: '通貨管理',
          path: '/event/:subdomain/:directory/currency',
          routeName: 'EventCurrency',
          includes: false,
        },
        {
          id: '02',
          name: 'QR管理',
          path: '/event/:subdomain/:directory/currency/qr',
          routeName: 'EventCurrencyQr',
          includes: true,
        },
        {
          id: '03',
          name: 'ワンタイムトークン',
          path: '/event/:subdomain/:directory/currency/onetime-token',
          routeName: 'EventCurrencyOnetimeToken',
          includes: false,
        },
      ];
    },
    pageNavs() {
      return this.navs.filter(e => {
        if ((!this.hasCustomersRole && !this.$permission.isGmo()) || !this.hasManageCurrencyRole) {
          return e.routeName !== 'EventCurrency';
        } else if (!this.hasManageQrRole) {
          return e.routeName === 'EventCurrency';
        }
        return true;
      });
    },
    hasGroup() {
      return this.eventGroups?.length;
    }
  },
  methods: {
    async getCategories() {
      const action = this.useTicketFlag ? 'getCategoriesList' : 'getCurrencyCategoriesList';
      await this.$store.dispatch(`currency/${action}`, { subdomain: this.subdomain });
    },
    async getQrCodeTickets() {
      if (this.useTicketFlag) await this.$store.dispatch('currency/getQrCodeTickets', { subdomain: this.subdomain });
    }
  }
};

<template>
  <div>
    <Modal class="modal-otp" v-if="isOpen">
      <template v-slot:headline>ワンタイムトークン一覧</template>
      <template v-slot:body>
        <FormRow>
          <template v-slot:label>
            <span class="custom-lable">
              <p>ワンタイムトークンを入力</p>
              <p class="lable-name">{{ qrCodeName }}</p>
            </span>
          </template>
          <template v-slot:content>
            <div class="form-content-row mt-3">
              <div class="form-icon">
                <input
                  class="form-control"
                  type="text"
                  name="otpName"
                  placeholder="ワンタイムトークンを入力して検索"
                  v-trim
                  v-model="searchForm.otpName"
                  @keyup="searchOneTimeToken"
                />
              </div>
            </div>
          </template>
        </FormRow>
        <section class="section">
          <div class="row">
            <div class="col">
              <Panel type="board" :padding="false">
                <template v-slot:body>
                  <div class="scrollX">
                    <TableWithCheckbox
                      :storeModule="storeModule"
                      :dataTable="dataTable"
                      :count="otpCount"
                      :labels="labels"
                      :currentCheckedItems="selectedOtpIds"
                      @select-items="handleSelectedItems"
                      ref="table"
                    ></TableWithCheckbox>
                  </div>
                </template>
                <template v-slot:footer>
                  <PaginationLog
                    ref="pagination"
                    :listLength="otpCount"
                    :searchConditions="searchConditions"
                    :subdomain="subdomain"
                    :modulePath="modulePath"
                    :limit="10"
                  ></PaginationLog>
                </template>
              </Panel>
            </div>
          </div>
        </section>
      </template>
      <template v-slot:footer>
        <ul class="listGrid justify-content-center">
          <li class="listGrid-item pos-start">
            <ActionButton
              :disabled="isDisable"
              class="btn btn-lg btn-black"
              :handle-submit="hanldeClickDownload"
              button-text="CSVダウンロード"
            />
          </li>
          <li class="listGrid-item">
            <ActionButton
              class="btn btn-lg btn-white"
              :handle-submit="cancelOneTimeToken"
              button-text="キャンセル"
            />
          </li>
          <li class="listGrid-item">
            <ActionButton
              :disabled="!isBtnEnable"
              class="btn btn-lg btn-blue"
              :handle-submit="openConfirmation"
              button-text="無効化する"
            />
          </li>
        </ul>
      </template>
    </Modal>
    <ModalConfirm
      :handleCloseModal="() => closeModal('confirmationModal')"
      :isConfirmModalShown="confirmationModal"
      :handleConfirmModal="hanldeDisableOtp"
      headerTitle="無効化の確認"
      :title="confirmationMessage"
      buttonText="無効化する"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
//component
import TableWithCheckbox from '@/components/TableWithCheckbox.vue';
import PaginationLog from '@/components/PaginationLog.vue';
import Modal from '@/components/Modal.vue';
import FormRow from '@/components/FormRow.vue';
import ModalConfirm from '@/components/ModalConfirm.vue';
//constants
import { StatusConstants } from '@/constants/status';
//mixins
import modal from '@/mixins/plugin/modal';
import search from '@/mixins/plugin/search';
//helpers
import { copyToClipboard } from '@/helpers/common';
import { breakLineWithComma } from '@/helpers/formatData';

export default {
  name: 'ModalOneTimeToken',
  props: ['isOpen', 'qrCodeName', 'releaseOtpId', 'isDisable'],
  data: function() {
    return {
      storeModule: 'currency',
      searchFields: ['otpName'],
      selectedItems: {
        allFlag: false,
        ids: [],
        count: 0,
      },
      typingInterval: null,
      selectedOtpIds: [],
      confirmationModal: false,
      searchConditions: {},
      initialSearchConditions: {},
    };
  },
  components: {
    FormRow,
    TableWithCheckbox,
    PaginationLog,
    Modal,
    ModalConfirm
  },
  mixins: [modal, search],
  computed: {
    ...mapGetters({
      otpList: 'currency/otpList',
      otpCount: 'currency/otpCount',
      otpInactiveCount: 'currency/otpInactiveCount',
      otpTotal: 'currency/otpTotal',
      isClientChild: 'auth/isClientChild',
      hasShopRole: 'auth/hasShopRole',
      useTicketFlag: 'event/useTicketFlag',
    }),
    statusClass: function() {
      return (status) => {
        switch (status) {
          case 0:
            return 'status-blue';
          case 1:
            return 'status-gray';
          case 2:
            return 'status-red';
        }
        return false;
      };
    },
    subdomain() {
      return this.$route.params.subdomain;
    },
    labels() {
      return [
        { isCheckbox: true },
        { key: 'name', name: 'ワンタイムトークン' },
        { key: 'status', name: 'ステータス' },
        { key: 'customerId', name: 'user ID', tdClass: 'pre-line' },
        { isButtonColumn: true }
      ];
    },
    dataTable() {
      return (this.otpList || []).map((item) => {
        const isDisabledCheckbox = [StatusConstants.otpStatus[1].value, StatusConstants.otpStatus[2].value].includes(item.status);
        return {
          ...item,
          customerId: item?.customerNumberPut ? breakLineWithComma((item.customerNumberPut + ',') + item?.customerId) : item?.customerId,
          isCheckboxDisabled: isDisabledCheckbox,
          status: {
            content: StatusConstants.otpStatus.find(status => status.value === item.status).label,
            class: this.statusClass(item.status),
            value: item.status,
          },
          buttonLinks: [
            {
              isNotShown: !item?.url,
              content: 'URLをコピー',
              class: 'btn btn-sm btn-black',
              function: () => copyToClipboard(item?.url, 'copyUrlSuccess'),
            },
          ],
        };
      })
    },
    countSelected() {
      if(!this.selectedItems.allFlag) return this.selectedItems.count;
      return this.selectedItems.count - this.otpInactiveCount;
    },
    confirmationMessage() {
      return `${this.countSelected}件無効化しますがよろしいですか？`;
    },
    isSelectedItemsExisted() {
      return this.selectedItems.allFlag || this.selectedItems.ids.length > 0;
    },
    isBtnEnable() {
      if(!this.selectedItems.allFlag) return this.selectedItems.ids.length > 0;
      return this.selectedItems.count !== this.otpInactiveCount;
    },
    modulePath() {
      return this.useTicketFlag ? 'currency/getOtpList' : 'currency/getCurrencyOtpList';
    }
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.resetData();
      }
    },
    releaseOtpId: {
      immediate: true,
      handler(val) {
        if(val) {
          this.initialSearchConditions.releaseOtpId = val;
          this.searchConditions.releaseOtpId = val;
        }
      }
    }
  },
  methods: {
    async hanldeClickDownload() {
      this.$emit('handle-click-download', this.releaseOtpId);
    },
    handleSelectedItems(nVal) {
      this.selectedItems = {
        allFlag: nVal.allFlag,
        ids: nVal.ids,
        count: nVal.count,
      };
    },
    closeOneTimeTokenModal() {
      this.$emit('update:isOpen', false);
      this.resetData();
    },
    closeConfirmationModal() {
      this.closeModal('confirmationModal')
    },
    cancelOneTimeToken() {
      this.closeOneTimeTokenModal();
    },
    resetList() {
      this.resetSearch();
    },
    openConfirmation() {
      if (this.isSelectedItemsExisted) {
        this.confirmationModal = true
      }
    },
    searchOneTimeToken(event) {
      const text = event.target.value;
      clearTimeout(this.typingInterval);
      this.typingInterval = setTimeout(() => {
        if (text?.trim()) {
          this.searchForm.otpName = text;
          this.search();
        } else {
          this.resetList();
        }
        if (this.otpList?.length) {
          this.$refs.table.resetCheck();
        }
      }, 300);
    },
    resetCheckbox() {
      this.$emit('on-reset-checkbox', true);
    },
    resetData() {
      this.selectedItems = {
        allFlag: false,
        ids: [],
        count: 0,
      };
      this.typingInterval = null;
      this.selectedOtpCount = 0;
      this.selectedOtpIds = [];
      this.resetList();
      this.$store.commit('currency/RESET_OTP_LIST');
    },
    async hanldeDisableOtp() {
      const action = this.useTicketFlag ? 'disableOtp' : 'disableCurrencyOtp';
      const result = await this.$store.dispatch(`currency/${action}`, {
        subdomain: this.subdomain,
        ...this.selectedItems,
        releaseOTPId: this.releaseOtpId,
        searchCondition: {
          ...this.searchForm
        }
      });
      if (result) {
        this.$message.showSuccess('disableSuccess');
        this.$refs.pagination.resetPagination();
        this.confirmationModal = false;
        this.$refs.table.resetCheck();
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.text-count {
  margin-left: auto;
  margin-right: 0;
}
.modal-otp {
  ::v-deep .modal-panel {
    height: 90vh;
    min-height: 90vh;
  }

  .form-row {
    margin-bottom: 16px;
  }

  ::v-deep .modal-body {
    padding: 32px 32px 16px;
  }

  .scrollX > div {
    height: calc(90vh - 306px);
    overflow: auto;
  }
}
.custom-lable {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.lable-name {
  color: #5e81f4;
}
</style>

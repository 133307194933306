<template>
  <Modal @close="handleCloseModal" v-if="isConfirmModalShown">
    <template v-slot:headline>{{ headerTitle }}</template>
    <template v-slot:body>
      <p class="description text-align-center">
        {{ title }}
      </p>
      <ul class="listGrid justify-content-center">
        <li class="listGrid-item">
          <button
            class="btn btn-lg btn-white"
            type="button"
            @click="handleCloseModal"
          >
            キャンセル
          </button>
        </li>
        <li class="listGrid-item">
          <ActionButton
            :class="[colorClass, { 'btn btn-lg': true }]"
            :handle-submit="handleConfirmModal"
            :button-text="buttonText"
          />
        </li>
      </ul>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue';
import modal from '@/mixins/plugin/modal';

export default {
  components: {
    Modal,
  },
  mixins: [modal],
  props: {
    color: {
      type: String,
      default: 'red',
    },
    handleConfirmModal: {
      type: Function,
      required: true
    },
    handleCloseModal: {
      type: Function,
      required: true
    },
    headerTitle: {
      type: String,
      default: '削除',
    },
    title: {
      type: String,
      default: '対象のQRコードを削除しますか？',
    },
    buttonText: {
      type: String,
      default: '削除する',
    },
    isConfirmModalShown: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    colorClass() {
      return this.color ? `btn-${this.color}` : '';
    }
  }
};
</script>
